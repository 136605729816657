import React, { useEffect, useState } from "react";
import { ShoppingCartOutlined } from "@mui/icons-material";
import CartIndicator from "./CartIndicator";
import { useSelector } from "react-redux";

export default function ShoppingCartWithIndicator() {

    const cart = useSelector((state) => state.cart.cart);
    const [totalItems, setTotalItems] = useState(0);
    useEffect(() => {
        if (!cart) {
            setTotalItems(0);
            return;
        }
        const total = cart.reduce((acc, item) => acc + 1, 0);
        setTotalItems(total);
    }, [cart]);

    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <ShoppingCartOutlined color="secondary" />
            <CartIndicator
                number={totalItems}
            />
        </div>
    );
};