import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import MenuItemDetails from "./MenuItemDetails";

export default function MenuItem({ item }) {
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  return (
    <Card sx={{ mb: 5, minHeight: 150, maxHeight: 350 }}>
      <CardActionArea
        onClick={showModal ? null : toggleModal}
        disableRipple={showModal}
        sx={{
          bgcolor: "primary.light",
        }}
      >
        <MenuItemDetails
          item={item}
          showModal={showModal}
          onClose={hideModal}
        />

        {item.image
          ? <>
            <CardMedia
              component="img"
              alt={"menu"}
              height="150"
              image={item.image}
              title={item.name}
              style={{ objectFit: item.image ? 'cover' : 'contain' }}
            />
            <CardContent>
              <Typography
                gutterBottom
                noWrap
                variant="h6"
                component="h2"
                className="item-title"
              >
                {item.name}
              </Typography>
              <Typography variant="body2" component="p">
                {item.price !== '0' ? <><small>$</small>{item.price / 100}</> : 'Price varies'}
              </Typography>
              <Typography noWrap variant="body2" component="p" className="item-desc">
                {item.description}
              </Typography>
            </CardContent>
          </>
          : <>
            <div style={{ height: "150px" }}></div>
            <CardContent>
              <Typography
                gutterBottom
                noWrap
                variant="h6"
                component="h2"
                className="item-title"
              >
                {item.name}
              </Typography>
              <Typography variant="body2" component="p">
                {item.price !== '0' ? <><small>$</small>{item.price / 100}</> : 'Price varies'}
              </Typography>

              <Typography noWrap variant="body2" component="p">
                {item.description}
              </Typography>
            </CardContent>
          </>}

      </CardActionArea>
    </Card >
  );
}

