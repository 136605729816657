import "./App.css";
import Home from "./pages/Home";
import NavBar from "./components/NavBar";
import { ThemeProvider } from "@emotion/react";
import { THEME } from "./style/Themes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menu from "./pages/Menu";
import Mission from "./pages/Mission";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Cart from "./pages/Cart";

import { Provider } from 'react-redux';
import { store } from "./store/Store";
import Reviews from "./pages/Reviews";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={THEME}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/legal/report-issue" element={<Contact />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
