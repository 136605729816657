import { CircularProgress, Typography } from "@mui/material";

export default function LoadingIndicator() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10%",
      }}
    >
      <Typography gutterBottom variant="h4">
        Loading...
      </Typography>
      <CircularProgress color="primary" thickness={3} size={60} />
    </div>
  );
}
