import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  FormControl,
  Paper,
  Typography,
} from "@mui/material";
import { Body } from "./common/Text";

const textFieldStyle = { marginBottom: "10px" };

export default function ContactForm({ ...props }) {
  const [submitted, setSubmitted] = React.useState(false);
  const { handleSubmit, control } = useForm();

  const onSubmit = async (data) => {
    await submitData(data);
    setSubmitted(true);
  };

  // Send email via proxy
  async function submitData(data) {
    return fetch('/api/email', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then(response => response.json());
  };

  return (
    <Paper
      elevation={3}
      style={{
        maxWidth: 600,
        padding: 24,
        margin: 16,
      }}
      {...props}
    >
      {submitted ? <SubmittedConfirmation /> : <FormField />}
    </Paper>
  );

  function FormField() {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth>
          <h2>Contact Us</h2>
        </FormControl>
        <FormControl fullWidth style={textFieldStyle}>
          <Controller
            name="from_name"
            control={control}
            render={({ field }) => (
              <TextField {...field} required label="Name" variant="outlined" />
            )}
          />
        </FormControl>

        <FormControl fullWidth style={textFieldStyle}>
          <Controller
            name="from_email"
            control={control}
            render={({ field }) => (
              <TextField {...field} required label="Email" variant="outlined" />
            )}
          />
        </FormControl>

        <FormControl fullWidth style={textFieldStyle}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Message"
                multiline
                rows={4}
                variant="outlined"
              />
            )}
          />
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: 16 }}
        >
          Submit
        </Button>
      </form>
    );
  }
}

function SubmittedConfirmation() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography gutterBottom variant="h4" fontWeight={"bold"} color={"green"}>
        Success!
      </Typography>
      <Body gutterBottom>Your inquiry has been submitted.</Body>
      <Body>You will hear back from us shortly.</Body>
    </div>
  );
}
