import { THEME } from "./Themes";

const Styles = {
  footer: {
    color: THEME.palette.secondary.main,
    pl: "10px",
    ml: 1,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export { Styles };
