import ContactForm from "../components/ContactForm";

export default function Contact() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "95vh",
        paddingTop: 32,
      }}
    >
      <ContactForm />
    </div>
  );
}
