import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { FixedSizeList } from "react-window";

export default function Dropdown({ value, onChange, options, label, ...props }) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = (value) => {
        onChange({ target: { value } });
        handleClose();
    };

    const renderRow = ({ index, style }) => (
        <MenuItem
            style={style}
            key={options[index]}
            value={options[index]}
            onClick={() => handleSelect(options[index])}
        >
            {options[index]}
        </MenuItem>
    );

    return (
        <FormControl required fullWidth {...props}>
            <InputLabel id="dropdown-label">{label}</InputLabel>
            <Select
                name={label}
                variant='outlined'
                label={label}
                labelId="dropdown-label"
                id="number-dropdown"
                value={value}
                onChange={onChange}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,
                        },
                    },
                    MenuListProps: {
                        component: React.forwardRef((props, ref) => (
                            <div ref={ref} {...props}>
                                <FixedSizeList
                                    height={200}
                                    width="100%"
                                    itemSize={35}
                                    itemCount={options.length}
                                    outerElementType="div"
                                    innerElementType="ul"
                                >
                                    {renderRow}
                                </FixedSizeList>
                            </div>
                        )),
                    },
                }}
            >
                {options.map((number) => (
                    <MenuItem key={number} value={number}>
                        {number}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};