import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: "#5D3FD3",
      light: "#cec5f1",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

THEME = responsiveFontSizes(THEME);
export { THEME };
