import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

import { v4 as uuid } from 'uuid';

import { Close } from "@mui/icons-material";
import { Body } from "../common/Text";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/CartSlice";

import Dropdown from "../common/Dropdown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const numberOfPeopleOptions = Array.from({ length: 500 }, (_, i) => (i + 1));

export default function MenuItemDetails({ item, showModal, onClose }) {

  const eventDetails = useSelector((state) => state.eventDetails.eventDetails);

  const [modifiers, setModifiers] = React.useState([]);
  const [selectedModifiers, setSelectedModifiers] = React.useState({});

  const [numberOfPeople, setNumberOfPeople] = React.useState('');
  const handleChangeNumberOfPeople = (event) => setNumberOfPeople(event.target.value);

  const dispath = useDispatch();

  // Add an item to the redux cart  
  function handleAddToCart() {
    const internalId = uuid();
    const id = item.id;
    const name = item.name;
    const image = item.image;
    const price = item.price;
    const modifiers = selectedModifiers;

    const quantity = numberOfPeople; // price of 0 means quantity is driven by size, not number of people.
    dispath(addToCart({
      internalId,
      id,
      name,
      image,
      price,
      modifiers,
      quantity,
    }));
    clearSelectedItems();
    onClose();
  }

  // Adds a modifier to the selected modifier map.
  // If two modifiers are already selected, don't add the new modifiers. 
  const handleSelectModifier = (modifier, listId, isMultiple) => {
    setSelectedModifiers({
      ...selectedModifiers,
      [listId]: isMultiple ? modifier.slice(0, 2) : modifier.length <= 1 ? modifier.slice(0, 1) : modifier.slice(1, 2),

    });
  };

  function clearSelectedItems() {
    setSelectedModifiers({});
    setNumberOfPeople('');
  }

  // Checks that each modifier has a selection
  const disableAdd = !numberOfPeople || Object.keys(selectedModifiers).length !== item.modifierLists.length;

  React.useEffect(() => {
    setModifiers(item.modifierLists);
    if (item.price !== '0' && eventDetails.numberOfPeople !== '') {
      setNumberOfPeople(eventDetails.numberOfPeople);
    } else {
      setNumberOfPeople(1);
    }
  }, [item, eventDetails]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={showModal}
      onClose={() => {
        clearSelectedItems();
        onClose();
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          padding: 0,
        }}
      >
        <DialogTitle variant="h3" gutterBottom sx={{ pb: 0 }}>
          {item.name}
        </DialogTitle>
        <IconButton size="large" onClick={() => {
          clearSelectedItems();
          onClose();
        }}>
          <Close />
        </IconButton>
      </div>

      <DialogTitle variant="h5" sx={{ pt: 0 }}>
        {item.price !== '0' ? <><small>$</small>{item.price / 100}</> : 'Price varies (see options)'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {item.image
              ? <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: 'cover',
                  backgroundColor: 'transparent'
                }}
                alt={item.name}
                src={item.image}
              />
              : <></>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                pl: "5%",
              }}
            >
              <Body gutterBottom sx={{ ml: 1 }}>
                {item.description}
              </Body>

              <Dropdown
                label='Quantity'
                value={numberOfPeople}
                onChange={handleChangeNumberOfPeople}
                options={numberOfPeopleOptions}
                sx={{ mt: 2, mb: 2, ml: 1 }}
              />
              {
                item.modifierLists.length === 0 ? <></>
                  :
                  <>
                    <Typography sx={{ ml: 1 }} variant="h4">Select options</Typography>
                    {
                      modifiers.map(list => {
                        let isMultipleSelect = list.selectionType === "MULTIPLE";
                        return (
                          <FormControl key={list.id} sx={{ m: 1, }}>
                            <InputLabel>{list.name}</InputLabel>
                            <Select
                              labelId="select-label"
                              isMultipleSelect // Showing as multiple to keep logic in other places the same
                              value={selectedModifiers[list.id] || []}
                              onChange={(event) => handleSelectModifier([event.target.value], list.id, isMultipleSelect)}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => selected.map(mod => mod.name).join(', ')}
                              MenuProps={MenuProps}
                            >
                              {
                                list.modifiers.map((modifier) =>
                                (
                                  <MenuItem key={modifier.name} value={modifier} >
                                    {
                                      isMultipleSelect && <Checkbox checked={(selectedModifiers[list.id] || []).indexOf(modifier) > -1} />
                                    }
                                    <ListItemText primary={modifier.name} secondary={'(+$' + modifier.price / 100 + ')'} />
                                  </MenuItem>
                                )
                                )
                              }
                            </Select>
                          </FormControl>
                        )
                      })
                    }
                  </>
              }


            </Box >
          </Grid >
        </Grid >
        <DialogActions>
          <Button onClick={() => {
            clearSelectedItems();
            onClose();
          }}>Cancel</Button>
          <Button disabled={disableAdd} onClick={handleAddToCart}>Add to cart</Button>
        </DialogActions>
      </DialogContent >
    </Dialog >
  );
}

