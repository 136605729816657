import { createSlice } from '@reduxjs/toolkit';


const eventDetailsSlice = createSlice({
    name: 'eventDetails',
    initialState: {
        eventDetails: {
            numberOfPeople: null,
            eventType: null,
        },
    },
    reducers: {
        updateNumberOfPeople: (state, action) => {
            state.eventDetails.numberOfPeople = action.payload;
        },
        updateEventType: (state, action) => {
            state.eventDetails.eventType = action.payload;
        },
        clearEventDetails: (state, action) => {
            state.eventDetails = {
                numberOfPeople: null,
                eventType: null,
            }
        },
    },
});

export const eventDetailsReducer = eventDetailsSlice.reducer;
export const {
    updateNumberOfPeople,
    updateEventType,
    clearEventDetails,
} = eventDetailsSlice.actions;