import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import { THEME } from "../style/Themes";
import Carousel from "react-material-ui-carousel";

export default function Testimonials(props) {

    const [testimonials, setTestimonials] = React.useState([{}]);

    React.useEffect(() => {
        fetch("/api/testimonials", {
            method: "GET",
            headers: {
                "Accept": "application/json",
            }
        }).then((res) => res.json())
            .then((data) => {
                const testimonialList = Object.keys(data).map(key => {
                    return {
                        name: data[key].name,
                        description: data[key].description
                    };
                });
                setTestimonials(testimonialList);
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <div {...props}>
            <Carousel
                autoPlay
                stopAutoPlayOnHover
                swipe
                indicators
                height={250}
                duration={500}
                animation="fade"
                indicatorIconButtonProps={{
                    style: {
                        color: THEME.palette.primary.light,
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        color: THEME.palette.primary.main,

                    }
                }}
                indicatorContainerProps={{
                    style: {
                        marginTop: '15px',
                    }

                }}
            >
                {
                    testimonials.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </div>
    )
}

function Item(props) {
    const [openModal, setOpen] = React.useState(false);
    console.log(props.item); // TODO: remove
    return (
        <Paper
            elevation={4}
            style={{
                padding: 24,
                margin: 16,
                height: 200,
            }}>
            <TestimonialDialog
                name={props.item.name}
                description={props.item.description}
                showModal={openModal}
                onClose={() => setOpen(false)}
            />
            <h2>{props.item.name ? props.item.name : <Skeleton />}</h2>
            <Typography component="div" variant="body" style={{
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
            }}>
                {props.item.name ? props.item.description : <Skeleton />}
            </Typography>

            {
                props.item.description && <Button onClick={
                    () => setOpen(true)
                } sx={{ pl: 0 }}>
                    Read more
                </Button>
            }
        </Paper >
    )
}

function TestimonialDialog({ name, description, showModal, onClose }) {
    return (
        <Dialog fullWidth maxWidth="lg" open={showModal}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    padding: 0,
                }}
            >
                <div style={{ alignSelf: 'flex-end' }}>
                    <IconButton size="large" onClick={() => {
                        onClose();
                    }}>
                        <Close />
                    </IconButton>
                </div>
                <div style={{ alignSelf: 'flex-start' }}>
                    <DialogTitle variant="h4" gutterBottom sx={{ pb: 0 }}>
                        {name}
                    </DialogTitle>
                </div>
            </div>
            <DialogContent>
                <p>{description}</p>
            </DialogContent>
        </Dialog>
    );
}