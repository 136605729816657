import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import ShoppingCartWithIndicator from "./cart/CartIndicatorIcon";

const pages = [
  {
    displayName: "Menu",
    route: "/menu",
  },
  {
    displayName: "Our Mission",
    route: "/mission",
  },
  {
    displayName: "Reviews",
    route: "/reviews",
  },
  {
    displayName: "Contact Us",
    route: "contact",
  },
  {
    displayName: "About",
    route: "about",
  },
];

const cart =
{
  displayName: "Cart",
  route: "/cart",
};


export default function NavBar() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenCart = (event) => {
    navigate(cart.route);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              objectFit: 'contain',
              height: '24px',
              width: "24px",
              mr: 1,
              display: { xs: "none", md: "flex", color: "#000000" },
            }}
            alt="Logo"
            src="./logo.png"
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "serif",
              fontWeight: 700,
              color: "#000000",
              textDecoration: "none",
            }}
          >
            D.E.T. Eatery
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.displayName}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(page.route);
                  }}
                >
                  <Typography textAlign="center">{page.displayName}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="img"
            sx={{
              objectFit: 'contain',
              height: '24px',
              width: "24px",
              mr: 1,
              display: { xs: "flex", md: "none", color: "#000000" },

            }}
            alt="Logo"
            src="./logo.png"
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "serif",
              fontWeight: 700,
              color: "#000000",
              textDecoration: "none",
            }}
          >
            D.E.T Eatery
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.displayName}
                onClick={() => {
                  handleCloseNavMenu();
                  navigate(page.route);
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.displayName}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Cart">
              <IconButton onClick={handleOpenCart} sx={{ p: 0 }}>
                <ShoppingCartWithIndicator />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
}
