// AnnouncementBanner.js
import React, { useState } from 'react';
import { THEME } from '../style/Themes';
import { IconButton, Typography } from '@mui/material';
import { Close } from "@mui/icons-material";


const AnnouncementBanner = ({ children }) => {
    const [visible, setVisible] = useState(true);

    if (!visible) return null;

    return (
        <div style={{
            backgroundColor: THEME.palette.primary.main,
            color: '#333',
            padding: '10px',
            marginTop: '5px',
            textAlign: 'center',
            width: '100%',
            cursor: 'pointer',
            top: 0,
            left: 0,
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }} onClick={() => window.location = '/menu'}>
            <Typography
                variant="h6"
                component="a"
                color="secondary"
                textDecoration="none"
                marginLeft='10px'
                textAlign='left'
            >
                <span>{children}</span>
            </Typography>
            <IconButton style={{
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
                color: '#333'
            }}
                onClick={() => setVisible(false)}>
                <Close htmlColor='white' />
            </IconButton>
        </div >
    );
};

export default AnnouncementBanner;