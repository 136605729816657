import { Close, } from "@mui/icons-material";
import { Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import { calculateItemPrice } from "../store/CartSlice";


export default function ConfirmationModal({ showModal, cart, deliveryInfo, tax, deliveryFee, serverCharge, deliveryDate, total, onClose }) {
    const printRef = React.useRef();
    const titleRef = React.useRef();

    const handlePrint = () => window.print();

    window.onafterprint = () => {
        printRef.current.style.display = 'inherit';
        titleRef.current.style.display = 'initial';


    }
    window.onbeforeprint = () => {
        printRef.current.style.display = 'none';
        titleRef.current.style.display = 'none';
    }

    const formattedDeliveryDate = deliveryDate ? deliveryDate.format("MMMM D, YYYY") : null;
    const formattedDeliveryTime = deliveryDate ? deliveryDate.format("hh:mm A") : null;

    return (
        <>
            <Dialog maxWidth="lg" open={showModal}>
                <div ref={titleRef}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "start",
                            paddingLeft: 4,
                        }}
                    >
                        <DialogTitle variant="h5" sx={{ pb: 0 }}>
                            Success!
                        </DialogTitle>
                        <IconButton size="large" onClick={onClose}>
                            <Close />
                        </IconButton>
                    </div>
                </div>

                <DialogContent sx={{ p: 4 }}>
                    <Typography variant="body1">Thank you for your order!</Typography>
                    <Typography variant="body1">We will send you a confirmation email shortly.</Typography>
                    <p style={{ marginBottom: "0", color: "#35558a" }}>
                        Delivery details
                    </p>
                    <hr
                        style={{
                            marginTop: "2px",
                            marginBottom: "4px",
                            height: "0",
                            backgroundColor: "transparent",
                            opacity: ".75",
                            borderTop: "2px dashed #9e9e9e",
                        }}
                    />


                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            width: "75%"
                        }}>
                            <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                                Delivery for
                            </p>
                        </div>
                        <p style={{ marginBottom: "0" }}>
                            {deliveryInfo.givenName} {deliveryInfo.familyName}
                        </p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            width: "75%"
                        }}>
                            <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                                Deliver to
                            </p>
                        </div>
                        <div>
                            <p style={{ marginBottom: 0 }}>
                                {deliveryInfo.addressLines[0]}
                            </p>
                            {
                                deliveryInfo.addressLines[1]
                                    ? <p style={{ marginBottom: 0, marginTop: 0 }}>
                                        {deliveryInfo.addressLines[1]}
                                    </p>
                                    : <></>
                            }
                            <p style={{ marginBottom: 0, marginTop: 0 }}>
                                {deliveryInfo.city}, {deliveryInfo.state} {deliveryInfo.zip}
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            width: "75%"
                        }}>
                            <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                                Deliver at
                            </p>
                        </div>
                        <div>
                            <p style={{ marginBottom: "0" }}>
                                {formattedDeliveryDate}
                            </p>
                            <p style={{ marginBottom: 0, marginTop: 0 }}>
                                {formattedDeliveryTime}
                            </p>
                        </div>
                    </div>
                    <p style={{ marginBottom: "0", color: "#35558a" }}>
                        Payment summary
                    </p>
                    <hr
                        style={{
                            marginTop: "2px",
                            marginBottom: "4px",
                            height: "0",
                            backgroundColor: "transparent",
                            opacity: ".75",
                            borderTop: "2px dashed #9e9e9e",
                        }}
                    />

                    {
                        cart.map(item =>
                            <div key={item.name}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                    <div style={{
                                        width: "75%"
                                    }}>
                                        <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                                            {`${item.name} (Qty: ${item.quantity})`}
                                        </p>
                                    </div>
                                    <p style={{ marginBottom: "0" }}>${calculateItemPrice(item).toFixed(2) * item.quantity}</p>
                                </div>

                                {
                                    item.modifiers
                                        ?
                                        <div style={{
                                            width: "50%"
                                        }}>
                                            <p>
                                                {
                                                    [].concat(...Object.values(item.modifiers))
                                                        .map((modifier, index) => (
                                                            <span key={`modifier_${index}`}>
                                                                {modifier.name}
                                                                {index !== [].concat(...Object.values(item.modifiers)).map(e => e.name).length - 1 ? ', ' : ''}
                                                            </span>
                                                        ))
                                                }
                                            </p>
                                        </div>
                                        : <></>
                                }
                            </div>
                        )
                    }

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <p style={{ marginBottom: "0" }}>Delivery Fee</p>
                        <p style={{ marginBottom: "0" }}>${(deliveryFee / 100).toFixed(2)}</p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <p style={{ marginBottom: "0" }}>Service Fee</p>
                        <p style={{ marginBottom: "0" }}>${(serverCharge / 100).toFixed(2)}</p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "1",
                    }}>
                        <p style={{ marginBottom: "0" }}>Tax</p>
                        <p style={{ marginBottom: "0" }}>${tax.toFixed(2)}</p>
                    </div>

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <p style={{ fontWeight: "bold" }}>Total</p>
                        <p style={{ fontWeight: "bold", color: "#35558a" }}>
                            ${total.toFixed(2)}
                        </p>
                    </div>

                    <Container style={{
                        display: "flex",
                        justifyContent: "center",
                        borderTop: "0",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                    }}>
                        <Button ref={printRef} size="lg" onClick={handlePrint}>Print</Button>
                    </Container>
                </DialogContent>
            </Dialog >
        </>
    );
}