import { createSlice } from '@reduxjs/toolkit';

function calculateItemPrice(item) {
    let total = parseInt(item.price);
    for (const entry of Object.values(item.modifiers)) {
        total += entry.reduce((acc, modifier) => acc + parseInt(modifier.price), 0);
    }
    return total / 100;
}

function compareDictionaries(dict1, dict2) {
    const keys1 = Object.keys(dict1);
    const keys2 = Object.keys(dict2);

    if (keys1.length !== keys2.length) {
        return false; // Different number of keys
    }

    for (const key of keys1) {
        if (!dict2[key] || !arraysEqual(dict1[key], dict2[key])) {
            return false; // Arrays are not equal or key missing in dict2
        }
    }

    return true;
}

function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (!objectsEqual(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
}

function objectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
    },
    reducers: {
        addToCart: (state, action) => {
            const itemInCart = state.cart.find((item) => item.id === action.payload.id && compareDictionaries(item.modifiers, action.payload.modifiers));
            if (itemInCart) {
                itemInCart.quantity++;
            } else {
                state.cart.push({ ...action.payload });
            }
        },
        incrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload.id && compareDictionaries(item.modifiers, action.payload.modifiers));
            item.quantity++;
        },
        decrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload.id && compareDictionaries(item.modifiers, action.payload.modifiers));
            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
        },
        removeItem: (state, action) => {
            const removeItem = state.cart.filter((item) =>
                item.id !== action.payload.id || !compareDictionaries(item.modifiers, action.payload.modifiers)
            );
            state.cart = removeItem;
        },
        clearCart: (state, action) => {
            state.cart = [];
        },
    },
});

export const cartReducer = cartSlice.reducer;
export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    clearCart,
} = cartSlice.actions;

export { calculateItemPrice };