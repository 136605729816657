import React from "react";
import MenuItem from "../components/menu/MenuItem";
import LoadingIndicator from "../components/common/LoadingIndicator";
import { FormControl, Grid, InputLabel, Paper, Select, Typography, MenuItem as MuiMenuItem } from "@mui/material";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateEventType, updateNumberOfPeople } from "../store/EventDetailSlice";
import Dropdown from "../components/common/Dropdown";

export default function Menu() {

  const dispatch = useDispatch();
  const eventDetails = useSelector((state) => state.eventDetails.eventDetails);

  const [menu, setMenu] = React.useState(null);

  const handleChangeNumberOfPeople = (event) => dispatch(updateNumberOfPeople(event.target.value));
  const handleChangeEventType = (event) => dispatch(updateEventType(event.target.value))

  React.useEffect(() => {
    if (menu) {
      return;
    }
    fetch("/api/menu", {
      method: "GET",
      headers: {
        "Accept": "application/json",
      }
    }).then((res) => res.json())
      .then((data) => {
        // Group items by category
        const groupedMenu = data.reduce((acc, item) => {
          if (!acc[item.category.name]) {
            acc[item.category.name] = [];
          }
          acc[item.category.name].push(item);
          return acc;
        }, {});

        // Convert the groupedMenu object into an array of entries and sort them
        const sortedMenuEntries = Object.entries(groupedMenu).sort(([keyA], [keyB]) => {
          if (keyA === 'Specials') return -1; // Place 'Specials' at the top
          if (keyB === 'Specials') return 1;
          if (keyA === 'Meats') return -1; // Place 'Meats' at the second spot
          if (keyB === 'Meats') return 1;
          if (keyA === 'Sides') return -1; // Place 'Sides' at the third spot
          if (keyB === 'Sides') return 1;
          return 0;
        });

        // Convert sorted entries back into an object
        const sortedGroupedMenu = Object.fromEntries(sortedMenuEntries);

        setMenu(sortedGroupedMenu);
      });
  }, [menu]);

  return menu === null ? (
    <>
      <LoadingIndicator />
    </>
  ) : (
    <>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <div style={{
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "2vw",
          paddingRight: "2vw",
        }}>

          <NumberOfPeopleDropDown value={eventDetails.numberOfPeople} onChange={handleChangeNumberOfPeople} sx={{ mt: 3 }} />
          <EventTypeDropDown value={eventDetails.eventType} onChange={handleChangeEventType} sx={{ mt: 3, ml: 1 }} />
        </div>
      </div>
      <MenuGrid menu={menu} />
      <Footer />
    </>
  );
}

const MenuGrid = ({ menu }) => {
  return (
    <Grid container spacing={3} sx={{ pl: 5, pr: 5, pt: 2, pb: 10 }}>
      {Object.keys(menu).map((category) => (
        <Grid item xs={12} key={category}>
          <Paper elevation={8} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>{category}</Typography>
            <Grid container spacing={3}>
              {menu[category].map((item) => (
                <Grid item xs={12} sm={4} md={3} key={item.id}>
                  <MenuItem item={item} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

const EventTypeDropDown = ({ value, onChange, ...props }) => {
  const eventTypes = [
    'Birthday',
    'Graduation',
    'Anniversary',
    'Baby Shower',
    'Bridal Shower',
    'Rehearsal Dinner',
    'Retirement',
    'Bachelor Party',
    'Bachelorette Party',
    'Bar Mitzvah',
    'Bat Mitzvah',
    'Repast / Memorial Service Reception',
    'Family Reunion',
    'Wedding',
    'Quinceañera',
    'Holiday Party',
    'Business / Office / Corporate Function',
    'Other',
  ];

  return (
    <FormControl required fullWidth {...props}>
      <InputLabel id="event-type-dropdown-label">Event Type</InputLabel>
      <Select name='eventType' variant='outlined' label='Event Type' labelId="event-type-dropdown-label" id="event-dropdown" value={value} onChange={onChange}>
        {eventTypes.map((event) => (
          <MuiMenuItem key={event} value={event}>
            {event}
          </MuiMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


const NumberOfPeopleDropDown = ({ value, onChange, label, ...props }) => {
  const numberOfPeopleOptions = Array.from({ length: 500 }, (_, i) => (i + 1));

  return (
    <Dropdown
      label='Number of People'
      value={value}
      onChange={onChange}
      options={numberOfPeopleOptions}
      {...props}
    />
  );
}