import PropTypes from "prop-types";

import { Typography } from "@mui/material";
import { Body } from "../components/common/Text";

export default function About() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography gutterBottom variant="h1" fontWeight="light">
        About Us
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "50px",
          paddingBottom: "50px",
        }}
      >
        {/* <Section title="Purchase Agreement">
          Business terms would be discussed here... Depending on the length, I
          would put them on a new page and there would be a link here the user
          would click. The name of this contract could be anything.
        </Section> */}
        <Section title="Terms of Use">
          By using this website, you agree to our <a href="/legal/terms-of-use.html">Terms of Use</a> and <a href="/legal/privacy.html">Privacy
            Policy</a>.
        </Section>
        <Section title="Payments">
          All card payments, inclduing Google Pay are conducted through the third party provider <a href="https://squareup.com/us/en">Square</a>.
          ACH payments are conducted through the third party provider <a href="https://plaid.com/">Plaid</a>.
          D.E.T. Eatery does not store your payment information.
        </Section>
        {/* <Section title="Section 3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Section>
        <Section title="Section 4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Section>
        <Section title="F.A.Q.">
          <div style={{ marginLeft: "2vw" }}>
            <FAQ question="Question 1">
              Answer to the question... This is just a placeholder for now to
              demonstrate the page layout. This could be as long or short as
              needed.
            </FAQ>
            <FAQ question="Question 2">
              Answer to the question... This is just a placeholder for now to
              demonstrate the page layout. This could be as long or short as
              needed.
            </FAQ>
            <FAQ question="Question 3">
              Answer to the question... This is just a placeholder for now to
              demonstrate the page layout. This could be as long or short as
              needed.
            </FAQ>
            <FAQ question="Question 4">
              Answer to the question... This is just a placeholder for now to
              demonstrate the page layout. This could be as long or short as
              needed.
            </FAQ>
            <FAQ question="Question 5">
              Answer to the question... This is just a placeholder for now to
              demonstrate the page layout. This could be as long or short as
              needed.
            </FAQ>
          </div>
        </Section> */}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

function Section({ children, title }) {
  return (
    <>
      <Typography gutterBottom variant="h3">
        {title}
      </Typography>
      <Body gutterBottom sx={{ paddingBottom: "10px" }}>
        {children}
      </Body>
    </>
  );
}

Section.propTypes = {
  children: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function FAQ({ children, question }) {
  return (
    <>
      <Typography gutterBottom variant="h5">
        {question}
      </Typography>
      <Body sx={{ pb: 1 }} gutterBottom>
        {children}
      </Body>
    </>
  );
}

FAQ.propTypes = {
  children: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};
