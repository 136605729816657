import { Box, Button, Typography } from "@mui/material";
import { THEME } from "../style/Themes";
import { Body } from "../components/common/Text";
import Footer from "../components/Footer";
import { styled } from '@mui/material/styles';
import React from "react";
import Testimonials from "../components/Testimonials";
import AnnouncementBanner from "../components/Annoucement";

const FirstRow = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0px",
  },
  [theme.breakpoints.up('md')]: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "50px",
  },
}));

const TestimonialsRow = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
  },
  [theme.breakpoints.up('sm')]: {
    justifyContent: "center",
    alignItems: "center",
    margin: "0 50px",
  },
}));

const ImageBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    alignItems: "center",
    margin: "10px",
  },
  [theme.breakpoints.up('md')]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    padding: "0 50px",
    margin: 0,
  },
}));

const TextBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    alignItems: "center",
    margin: "10px",
  },
  [theme.breakpoints.up('md')]: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
  },
}));

export default function Home() {
  return (
    <>
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AnnouncementBanner>Let D.E.T. Eatery cook your Thanksgiving dinner! Thanksgiving Special for only <small>$</small>299. <br />Deadline to order is <strong>November 21st</strong></AnnouncementBanner>
        <Typography variant="h1">D.E.T. Eatery</Typography>
        <Typography variant="h5" sx={{ fontStyle: "italic" }}>
          A blessing in every bite
        </Typography>
      </header>
      <div className="body">
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              height: "150px",
              backgroundColor: THEME.palette.primary.light,
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
              Only the best food with a blessing in every bite.
            </Typography>
            <Button
              variant="contained"
              href="/menu"
              color="primary"
              style={{ borderRadius: "24px", height: "50px", width: "130px" }}
            >
              Order Now
            </Button>
          </div>
          <FirstRow>
            <TextBox>
              <Typography gutterBottom variant="h3">Our Mission</Typography>
              <Body>
                Our mission for DET Eatery is to spread and show God's love
                through our gifts and talent of cooking, and to provide
                excellent customer service to everyone we encounter.
              </Body>
              <Button
                variant="text"
                color="primary"
                href="/mission"
                style={{
                  borderRadius: "16x",
                  height: "50px",
                  width: "90px",
                  marginTop: 10,
                  padding: 0
                }}
              >
                Learn More
              </Button>
              <Typography gutterBottom variant="h3" sx={{ mt: 1 }}>Why choose us?</Typography>
              <Body>
                At D.E.T. Eatery, we pride ourselves on our ability to cater to a diverse array of events, ensuring that every dish we serve is cooked to perfection and delivered fresh to your venue.
                Our commitment to quality and freshness is unwavering, as we spread God's love through our cooking.
                We guarantee satisfaction with our extensive offerings, tailored to meet the unique needs and preferences of your guests.
                Trust us to add that special touch to your event with our exceptional offerings.
              </Body>
            </TextBox>
            <ImageBox>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                }}
                alt={'Image'}
                src={'https://items-images-sandbox.s3.us-west-2.amazonaws.com/files/e733deca00d21506bd471553e1deae7d15720182/original.jpeg'}
              />
            </ImageBox>

          </FirstRow>
          <TestimonialsRow>
            <TextBox>
              <Typography variant="h3">Testimonials</Typography>
              <Button
                variant="text"
                color="primary"
                href="/reviews"
                sx={{
                  borderRadius: "16x",
                  height: "50px",
                  width: "135px",
                  padding: 0
                }}
              >
                Leave us a review
              </Button>
            </TextBox>
            <Testimonials />
          </TestimonialsRow>

          <div
            style={{
              marginTop: "20px",
              backgroundColor: THEME.palette.primary.light,
              paddingBottom: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mt: 1,
              }}
            >
              Spreading God's love through cooking
            </Typography>
            <Body>
              For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life. (John 3:16)
            </Body>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}