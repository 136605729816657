// Values represented in meters and the associated delivery fee.
const twentyFiveMiles = {
    meters: 40234,
    fee: 7500,
}

const fortyNineMiles = {
    meters: 78858,
    fee: 15000,
}

const seventyFiveMiles = {
    meters: 120701,
    fee: 30000,
}

/** 
Function to calculate delivery fee based on distance
 
// Rates per milage:
<25 mi: $75.00
26-49 mi: $150.00
50-75 mi: $300.00
75+ mi: Not supported

@param distance represents the distance in meters
@returns the fee for the milage range or null if the distance is not supported.
*/
export default function calculateDeliveryFee(distance) {
    if (distance < twentyFiveMiles.meters) {
        return twentyFiveMiles.fee;
    } else if (distance < fortyNineMiles.meters) {
        return fortyNineMiles.fee;
    } else if (distance < seventyFiveMiles.meters) {
        return seventyFiveMiles.fee;
    } else {
        // Distance is not supported
        return null;
    }

}