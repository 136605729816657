import React, { Fragment } from "react";

export default function CartIndicator({ number }) {
    return number > 0 ? (
        <Fragment>
            <span style={{
                borderRadius: "1rem",
                height: "1rem",
                width: "1rem",
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.8rem",
                backgroundColor: "red",
                color: "white",
                position: "absolute",
                bottom: -5,
                right: -5,
            }}>
                {number}
            </span>
        </Fragment>
    ) : null;
};