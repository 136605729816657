import React from "react";
import { Body } from "../components/common/Text";
import { Box, Typography, styled } from "@mui/material";

const ImageBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    height: "60%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.down('md')]: {
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
}));

const MainPage = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    height: '100vh',
    marginRight: '100px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    marginRight: '0px',
  },
}));

const PageContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "50px",
    marginRight: "50px",
    paddingBottom: "50px",
    paddingTop: "20px",
  },
  [theme.breakpoints.down('md')]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
    paddingBottom: "20px",
    paddingTop: "10px",
  },
}));

export default function Mission() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <MainPage>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
          }}>
            <PageContainer
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography gutterBottom variant="h3">
                  Our Mission
                </Typography>
                <Body gutterBottom>
                  Our mission for DET Eatery is to spread and show God's love through
                  our gifts and talents of cooking, and to provide excellent customer
                  service to everyone we encounter.
                </Body>
                <Body gutterBottom>
                  Our vision in the near future is have several DET Eateries
                  throughout the world. Here at DET Eatery, we think big because we
                  serve a big God! One of our commitments is to feed the less
                  fortunate of God's children. God has given us all a purpose in life,
                  it's up to us to know what that purpose is and to fulfill that
                  purpose. Just remember you can do all things through Christ who
                  gives you strength.
                </Body>
                <Body gutterBottom>
                  DET Eatery is very excited to earn your business and appreciate you
                  taking the time to read our mission and vision statement. May God
                  bless you abundantly!
                </Body>
              </div>
            </PageContainer>
            <PageContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography gutterBottom variant="h3">
                  Our Story
                </Typography>
                <Body gutterBottom>
                  This is out story how D.E.T. Eatery was established. My husband and I always dreampt and prayed about having our own catering business for many years. We looked at commercial kitchens in the past and it was just not what we were looking for. After approximately 15 years, we caught ourselves downsizing after our sons graduated from high school and went on to their respective colleges. We looked at 2 or 3 houses and the last house we looked at was it. I can recall my husband saying this is it. No clue did we know as we walked down the stairs we saw that one of our prayers had been answered! We could not believe our eyes. There it was - our commecial kitchen. The scripture that came to my mind was "We walk by faith and not by sight".
                </Body>
                <Body gutterBottom>
                  We thank God for being a prayer answering God. This was how D.E.T. Eatery was manifested by God.
                </Body>
              </div>

            </PageContainer>

            <PageContainer
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography gutterBottom variant="h3">
                  Why choose us?
                </Typography>
                <Body gutterBottom>
                  At D.E.T. Eatery, we pride ourselves on our ability to cater to a diverse array of events, ensuring that every dish we serve is cooked to perfection and delivered fresh to your venue. Our commitment to quality and freshness is unwavering, as we spread God's love through our cooking. We guarantee satisfaction with our extensive offerings, tailored to meet the unique needs and preferences of your guests. Trust us to add that special touch to your event with our exceptional offerings.
                </Body>
              </div>

            </PageContainer>

          </div>
          <ImageBox>
            <Box
              component="img"
              sx={{
                objectFit: 'fill',
                height: "100%",
                width: "100%",
                pt: 5,
              }}
              alt="Image"
              src="https://items-images-sandbox.s3.us-west-2.amazonaws.com/files/c16ebd0ac9cf991f218ab26ca0c54d81c1dd99a5/original.jpeg"
            />
          </ImageBox>
        </MainPage >
      </div >
      {/* <Footer /> */}
    </>
  );
}
