import { useDispatch, useSelector } from 'react-redux';
import { Ach, CreditCard, Divider, GooglePay, PaymentForm } from 'react-square-web-payments-sdk';
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Add, Delete, Error, Remove } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { calculateItemPrice, clearCart, decrementQuantity, incrementQuantity, removeItem } from '../store/CartSlice';
import ConfirmationModal from './Confirmation';
import { clearEventDetails, updateEventType, updateNumberOfPeople } from '../store/EventDetailSlice';
import { Body } from '../components/common/Text';
import calculateDeliveryFee from '../utils/deliveryFee';

export default function Cart() {
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart.cart);
    const eventDetails = useSelector((state) => state.eventDetails.eventDetails);

    // ref to hold the timeout ID
    const debounceTimeout = useRef(null);

    const [deliveryError, setDeliveryError] = useState('');

    const [serviceCharge, setServiceCharge] = useState(0);
    const [serverCharge, setServerCharge] = useState(0);
    const [serviceTime, setServiceTime] = useState('');

    const [deliveryAddress, setDeliveryAddress] = useState('');

    const handleChangeServerCharge = (event) => {
        const value = event.target.value;
        setServiceTime(value);
    };

    const [readyForPayment, setReadyForPayment] = useState(false);
    const handleFormComplete = () => setReadyForPayment(true);

    const handleChangeDeliveryAddress = async (e) => {
        e.preventDefault();
        let addressLine1 = deliveryInfo.addressLines[0];
        let addressLine2 = deliveryInfo.addressLines[1];
        let city = deliveryInfo.city;
        let state = deliveryInfo.state;
        let zip = deliveryInfo.zip;

        if (!(addressLine1 && city && state && zip)) {
            return;
        }

        let address = `${addressLine1} ${addressLine2} ${city}, ${state} ${zip}`;

        await fetch(`/api/delivery-distance?address=${encodeURIComponent(address)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(data => {

                if (data.delivery === undefined || data.delivery.value === undefined) {
                    // TODO: handle error
                    setDeliveryError('The address you entered could not be found');
                    return;
                }
                let fee = calculateDeliveryFee(data.delivery.value)

                if (fee === null) {
                    setDeliveryError('The address you entered does not support delivery');
                    return;
                }

                setDeliveryAddress(address);
                setServiceCharge(fee);

                switch (serviceTime) {
                    case '2 hours':
                        setServerCharge(20000);
                        break;
                    case '4 hours':
                        setServerCharge(40000);
                        break;
                    default: setServerCharge(0);
                }
                setDeliveryError('');
            }).catch(error => {
                console.log('Error calculating service charge.');
            });
    };

    const getFormattedDeliveryAddress = () => {
        if (!deliveryAddress) {
            return;
        }
        let hasLine2 = deliveryInfo.addressLines[1] ? true : false;
        return `${deliveryInfo.addressLines[0]}\n${deliveryInfo.addressLines[1]}${hasLine2 ? '\n' : ''}${deliveryInfo.city}, ${deliveryInfo.state} ${deliveryInfo.zip}`;
    }

    const [subtotalPrice, setSubtotalPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [taxData, setTaxData] = useState({
        id: '',
        name: '',
        percentage: '',
    });
    const [taxes, setTaxes] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [billingInfo, setBillingInfo] = useState({
        givenName: '',
        familyName: '',
        email: '',
        phone: '',
        addressLines: ['', ''],
        city: '',
        state: '',
        countryCode: 'US',
        zip: '',
    });

    const [deliveryInfo, setDeliveryInfo] = useState({
        givenName: '',
        familyName: '',
        addressLines: ['', ''],
        city: '',
        state: '',
        countryCode: 'US',
        zip: '',
    });

    const isAddressComplete =
        deliveryInfo.addressLines[0] &&
        deliveryInfo.city &&
        deliveryInfo.state &&
        deliveryInfo.zip;

    const clearDeliveryAddress = () => {
        setDeliveryInfo({
            givenName: deliveryInfo.givenName,
            familyName: deliveryInfo.familyName,
            addressLines: ['', ''],
            city: '',
            state: '',
            countryCode: 'US',
            zip: '',
        });
        setDeliveryAddress('');
    };

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(true);
    const hideModal = () => setShowModal(false);

    const [paymentError, setPaymentError] = useState(false);
    const handlePaymentError = () => setPaymentError(true);
    const resetPaymentError = () => setPaymentError(false);

    const clearStore = () => {
        dispatch(clearCart());
        dispatch(clearEventDetails());
    };

    const handleBillingInfoChange = (event) => {
        setReadyForPayment(false);
        if (event.target.name.startsWith('addressLines')) {
            const index = Number(event.target.name.split('-')[1]);
            const newAddressLines = [...billingInfo.addressLines];
            newAddressLines[index] = event.target.value;
            setBillingInfo({
                ...billingInfo,
                addressLines: newAddressLines,
            });
        } else {
            setBillingInfo({
                ...billingInfo,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleDeliveryInfoChange = (event) => {
        setReadyForPayment(false);
        if (event.target.name.startsWith('addressLines')) {
            const index = Number(event.target.name.split('-')[1]);
            const newAddressLines = [...deliveryInfo.addressLines];
            newAddressLines[index] = event.target.value;
            setDeliveryInfo({
                ...deliveryInfo,
                addressLines: newAddressLines,
            });
        } else {
            setDeliveryInfo({
                ...deliveryInfo,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleEventTypeChange = (event) => dispatch(updateEventType(event.target.value));
    const handleNumberOfPeopleChange = (event) => dispatch(updateNumberOfPeople(event.target.value));


    // TODO: need to add delivery details AND billing details
    const sendConfirmationEmail = async () => {
        let data = {
            to_email: billingInfo.email,
            name: `${deliveryInfo.givenName} ${deliveryInfo.familyName}`,
            address: deliveryInfo.addressLines,
            city: deliveryInfo.city,
            state: deliveryInfo.state,
            zip: deliveryInfo.zip,
            deliveryFee: (serviceCharge / 100).toFixed(2),
            tax: taxes.toFixed(2),
            totalAmount: totalPrice.toFixed(2),
            deliveryDate: deliveryDate,
            items: cart.map(item => (
                {
                    name: item.name,
                    price: calculateItemPrice(item) * item.quantity,
                    quantity: item.quantity,
                    modifiers: item.modifiers
                        ?
                        [].concat(...Object.values(item.modifiers))
                            .map((modifier) => (
                                {
                                    name: modifier.name,
                                    price: modifier.price / 100,
                                }

                            ))
                        : []
                }
            )),
        }
        const response = await fetch('/api/order-confirmation', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    }

    /// Function to build the order for Square to process. 
    const getOrder = () => cart.map(({ id, quantity, modifiers }) => ({
        catalogObjectId: id,
        quantity: quantity.toString(),
        modifiers:
            [].concat(...Object.values(modifiers))
                .map(modifier => ({
                    name: modifier.name,
                    basePriceMoney: {
                        amount: modifier.price,
                        currency: "USD"
                    }
                })),
    }));

    const isFormComplete =
        billingInfo.givenName
        && billingInfo.familyName
        && billingInfo.addressLines[0]
        && billingInfo.city
        && billingInfo.countryCode
        && billingInfo.email
        && billingInfo.phone
        && billingInfo.zip
        && billingInfo.state
        && deliveryInfo.givenName
        && deliveryInfo.familyName
        && deliveryInfo.addressLines[0]
        && deliveryInfo.city
        && deliveryInfo.countryCode
        && deliveryInfo.zip
        && deliveryInfo.state
        && eventDetails.numberOfPeople
        && eventDetails.eventType
        && deliveryDate;

    function createAchTransactionId() {
        const uuid = crypto.randomUUID();
        return uuid;
    }

    // Fetch taxes from Square
    useEffect(() => {
        if (cart.length === 0) {
            return;
        }
        fetch("/api/taxes", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        })
            .then(response => response.json())
            .then((data) => setTaxData(data));
    }, [cart.length]);

    /// Calculate total taxes and prices by calling Square order preview API.
    /// The subtotal price will update immediately upon a change in the cart.
    /// The taxes and total price will update after a response is received from the API.
    /// The API is only called after 1 second of no changes to the cart. This helps prevent overcalling the API. 
    useEffect(() => {
        if (cart.length === 0) {
            return;
        }
        const subTotal = cart.reduce((acc, item) => acc + (calculateItemPrice(item) * item.quantity), 0);
        setSubtotalPrice(subTotal);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            // Runs this code after waiting 1000 ms.
            fetch("/api/calculate-order", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify({
                    amount: (totalPrice * 100).toFixed(0),
                    serviceChargeAmount: serviceCharge,
                    serverChargeAmount: serverCharge,
                    order: cart.map(({ id, quantity, modifiers }) => ({
                        catalogObjectId: id,
                        quantity: quantity.toString(),
                        modifiers:
                            [].concat(...Object.values(modifiers))
                                .map(modifier => ({
                                    name: modifier.name,
                                    basePriceMoney: {
                                        amount: modifier.price,
                                        currency: "USD"
                                    }
                                })),
                    })),
                    taxes: [{
                        catalogObjectId: taxData.id,
                        name: taxData.name,
                        scope: 'ORDER',
                    }]
                }),
            })
                .then(response => response.json())
                .then((data) => {

                    setTaxes(data.taxAmount / 100);
                    setTotalPrice(data.totalMoney / 100);
                });
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout.current);
        };
    }, [cart, taxData, totalPrice, serviceCharge, serverCharge]);

    const orderDetails = useRef({
        deliveryInfo: deliveryInfo,
        cart: cart,
        taxes: taxes,
        deliveryFee: serviceCharge,
        serverCharge: serverCharge,
        deliveryDate: deliveryDate,
        total: totalPrice,
    });

    const DisabledTextField = styled(TextField)(
        ({ theme }) => ({
            "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
                "-webkit-text-fill-color": theme.palette.text.primary,
            },
            "& .MuiInputLabel-outlined": {
                "-webkit-text-fill-color": theme.palette.text.primary,
            }
        }));

    return (
        <div style={{
            margin: "1vw"
        }}>
            <ConfirmationModal
                showModal={showModal}
                onClose={hideModal}
                cart={orderDetails.current.cart}
                deliveryInfo={orderDetails.current.deliveryInfo}
                tax={orderDetails.current.taxes}
                deliveryFee={orderDetails.current.deliveryFee}
                serverCharge={orderDetails.current.serverCharge}
                deliveryDate={orderDetails.current.deliveryDate}
                total={orderDetails.current.total}
            />
            {cart.length > 0 ?
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow key='header'>
                                    <TableCell key='preview'><Typography variant='h4'>Preview</Typography></TableCell>
                                    <TableCell key='item'><Typography variant='h4'>Item</Typography></TableCell>
                                    <TableCell key='price'><Typography variant='h4'>Price</Typography></TableCell>
                                    <TableCell key='quantity'><Typography variant='h4'>Quantity</Typography></TableCell>
                                    <TableCell key='subtotal'><Typography variant='h4'>Subtotal</Typography></TableCell>
                                    <TableCell key='remove' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cart.map((item) => (
                                    <TableRow key={item.internalId} >
                                        <TableCell key='preview' style={{ width: '1%' }}>
                                            {item.image ? <img src={item.image} alt={item.name} style={{
                                                width: '10vw',
                                                height: '10vh',
                                                objectFit: item.image ? 'cover' : 'contain',
                                            }} />
                                                : <></>
                                            }
                                        </TableCell>
                                        <TableCell key='name'>
                                            <Typography variant='h5'>{item.name}</Typography>

                                            {
                                                item.modifiers
                                                    ?

                                                    [].concat(...Object.values(item.modifiers))
                                                        .map((modifier, index) => (
                                                            <Typography key={modifier.name} variant='body2'>
                                                                {modifier.name}: (+<small>$</small>{modifier.price / 100})
                                                                {index !== [].concat(...Object.values(item.modifiers)).map(e => e.name).length - 1 ? ', ' : ''}
                                                            </Typography>
                                                        ))
                                                    : <></>
                                            }
                                        </TableCell>
                                        <TableCell key='amount'>
                                            <Typography variant='h5'>
                                                <small>$</small>{calculateItemPrice(item)}
                                            </Typography >
                                        </TableCell>
                                        <TableCell key='quantity'>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                margin: "10px 0",
                                                width: "100px",
                                            }}>
                                                <IconButton
                                                    size="small"
                                                    sx={{ backgroundColor: 'gainsboro' }}
                                                    aria-label="delete"
                                                    onClick={() => dispatch(decrementQuantity(item))}
                                                >
                                                    <Remove />
                                                </IconButton>
                                                <Typography variant='h5'>{item.quantity}</Typography>
                                                <IconButton
                                                    size="small"
                                                    sx={{ backgroundColor: 'gainsboro' }}
                                                    aria-label="delete"
                                                    onClick={() => dispatch(incrementQuantity(item))}
                                                >
                                                    <Add />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell key='subtotal'>
                                            <Typography variant='h5'>
                                                <small>$</small>{((calculateItemPrice(item)) * item.quantity).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell key='remove'>
                                            <IconButton
                                                size="sm"
                                                sx={{ backgroundColor: 'gainsboro' }}
                                                onClick={() => dispatch(removeItem(item))}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key='subtotal' >
                                    <TableCell key='total'>
                                        <Typography variant='h5'>Subtotal</Typography>
                                    </TableCell>
                                    <TableCell key='empty1' />
                                    <TableCell key='empty2' />
                                    <TableCell key='quantity' >
                                        <Typography variant='h5'>
                                            {cart.reduce((acc, item) => acc + (item.quantity), 0)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell key='price'>
                                        <Typography variant='h5'>
                                            <small>$</small>{subtotalPrice.toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key='tax' >
                                    <TableCell key='tax'>
                                        <Typography variant='h5'>Taxes</Typography>
                                    </TableCell>
                                    <TableCell key='empty1' />
                                    <TableCell key='empty2' />
                                    <TableCell key='empty3' />
                                    <TableCell key='price'>
                                        <Typography variant='h5'>
                                            <small>$</small>{taxes.toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key='delivery' >
                                    <TableCell key='delivery'>
                                        <Typography variant='h5'>Delivery Fee</Typography>
                                    </TableCell>
                                    <TableCell key='empty1' />
                                    <TableCell key='empty2' />
                                    <TableCell key='empty3' />
                                    <TableCell key='deliveryFee'>
                                        <Typography variant='h5'>
                                            <small>$</small>{(serviceCharge / 100).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key='service' >
                                    <TableCell key='service'>
                                        <Typography variant='h5'>Service Fee</Typography>
                                    </TableCell>
                                    <TableCell key='empty1' />
                                    <TableCell key='empty2' />
                                    <TableCell key='empty3' />
                                    <TableCell key='serviceFee'>
                                        <Typography variant='h5'>
                                            <small>$</small>{(serverCharge / 100).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key='total' >
                                    <TableCell key='total'>
                                        <Typography variant='h5'>Total</Typography>
                                    </TableCell>
                                    <TableCell key='empty1' />
                                    <TableCell key='empty2' />
                                    <TableCell key='empty3' />
                                    <TableCell key='price'>
                                        <Typography variant='h5'>
                                            <small>$</small>{totalPrice.toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                : <></>
            }
            {

                !deliveryAddress && cart.length > 0
                    ? <div style={{ marginBottom: '10vh' }}>
                        <form>
                            <Typography gutterBottom variant='h4' sx={{ ml: 1, mt: 1 }}>Enter Delivery Address</Typography>
                            <Body sx={{ ml: 1 }}>The address will be used to calculate the delivery fee</Body>
                            <DeliveryPriceList />

                            <Grid container spacing={2} sx={{ mb: 5, pl: 1, pr: 1 }}>
                                <Grid item xs={12} sm={12}>
                                    <TextField required fullWidth label="Address Line 1" name="addressLines-0" onChange={handleDeliveryInfoChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField fullWidth label="Address Line 2" name="addressLines-1" onChange={handleDeliveryInfoChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField required fullWidth label="City" name="city" onChange={handleDeliveryInfoChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField required fullWidth label="State" name="state" onChange={handleDeliveryInfoChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField required fullWidth label="Zipcode" name="zip" onChange={handleDeliveryInfoChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>

                                </Grid>
                            </Grid>
                        </form>

                        <form>
                            <Typography gutterBottom variant='h4' sx={{ ml: 1, mt: 1 }}>Do you require servers? </Typography>
                            <ServiceFeeList />
                            <ServiceDropDown
                                value={serviceTime}
                                onChange={handleChangeServerCharge}
                            />
                            <Button disabled={!isAddressComplete || !serviceTime} onClick={handleChangeDeliveryAddress}> Confirm</Button>
                            {deliveryError
                                ? <>
                                    <Body sx={{ color: 'red', pl: 1 }}>
                                        <Error sx={{ color: 'red', pr: 1 }} />{deliveryError}
                                    </Body>
                                </>
                                : <></>
                            }
                        </form>
                    </div>
                    : cart.length > 0 ? subtotalPrice >= 300 ? <>
                        <Paper elevation={8} style={{
                            display: "grid",
                            justifyContent: "center",
                            alignContent: "center",
                            padding: "20px",
                            margin: "50px",
                        }}>
                            <form>
                                <Typography gutterBottom variant='h4' sx={{ ml: 1 }}>Enter Billing Details</Typography>
                                <Grid container spacing={2} sx={{ mb: 5, pl: 1, pr: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.givenName} required fullWidth label="First Name" name="givenName" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.familyName} required fullWidth label="Last Name" name="familyName" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.email} required fullWidth label="Email" name="email" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.phone} required fullWidth label="Phone" name="phone" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.addressLines[0]} required fullWidth label="Address Line 1" name="addressLines-0" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.addressLines[1]} fullWidth label="Address Line 2" name="addressLines-1" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.city} required fullWidth label="City" name="city" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.state} required fullWidth label="State" name="state" onChange={handleBillingInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={billingInfo.zip} required fullWidth label="Zipcode" name="zip" onChange={handleBillingInfoChange} />
                                    </Grid>
                                </Grid>
                            </form>


                            <form>
                                <Typography gutterBottom variant='h4' sx={{ ml: 1 }}>Enter Delivery Details</Typography>
                                <Grid container spacing={2} sx={{ mb: 5, pl: 1, pr: 1 }}>
                                    <Grid item xs={12} sm={12}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                required
                                                views={['year', 'day', 'hours', 'minutes']}
                                                label="Delivery Date"
                                                name="delivery"
                                                value={deliveryDate}
                                                onChange={(value) => setDeliveryDate(value)}
                                                sx={{ width: "100%" }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={deliveryInfo.givenName} required fullWidth label="First Name" name="givenName" onChange={handleDeliveryInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField value={deliveryInfo.familyName} required fullWidth label="Last Name" name="familyName" onChange={handleDeliveryInfoChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DisabledTextField disabled fullWidth multiline label="Delivery Address" value={getFormattedDeliveryAddress()}></DisabledTextField>
                                        <Button onClick={clearDeliveryAddress}>Change</Button>
                                    </Grid>
                                </Grid>
                                <Typography gutterBottom variant='h4' sx={{ ml: 1 }}>Event Details</Typography>
                                <EventTypeDropDown
                                    value={eventDetails.eventType || ''}
                                    onChange={handleEventTypeChange}
                                />
                                <NumberOfPeopleDropDown
                                    value={eventDetails.numberOfPeople || ''}
                                    onChange={handleNumberOfPeopleChange}
                                />
                                {!readyForPayment && <Button disabled={!isFormComplete} onClick={handleFormComplete}>Confirm</Button>}
                                {!isFormComplete && <Typography variant='body2' color={'error'} sx={{ ml: 1 }}>Please fill out all required fields before confirming</Typography>}

                            </form>

                            {
                                (isFormComplete && readyForPayment) &&
                                <PaymentForm
                                    // applicationId="sandbox-sq0idb-p9KDDia0r7Pcyp2ssMdwtA"
                                    applicationId="sq0idp-Y-RzYLYqtP_kXLDaDS9tUA"
                                    cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                                        await fetch("/api/pay", {
                                            method: "POST",
                                            headers: {
                                                "Content-type": "application/json",
                                            },
                                            body: JSON.stringify({
                                                sourceId: token.token,
                                                verificationToken: verifiedBuyer.token,
                                                amount: (totalPrice * 100).toFixed(0),
                                                serviceChargeAmount: serviceCharge,
                                                serverChargeAmount: serverCharge,
                                                buyerEmailAddress: billingInfo.email,
                                                buyerPhoneNumber: billingInfo.phone,
                                                billing: {
                                                    firstName: billingInfo.givenName,
                                                    lastName: billingInfo.familyName,
                                                    address1: billingInfo.addressLines[0],
                                                    address2: billingInfo.addressLines[1],
                                                    city: billingInfo.city,
                                                    state: billingInfo.state,
                                                    zip: billingInfo.zip,
                                                },
                                                delivery: {
                                                    firstName: deliveryInfo.givenName,
                                                    lastName: deliveryInfo.familyName,
                                                    address1: deliveryInfo.addressLines[0],
                                                    address2: deliveryInfo.addressLines[1],
                                                    city: deliveryInfo.city,
                                                    state: deliveryInfo.state,
                                                    zip: deliveryInfo.zip,
                                                    eventType: eventDetails.eventType,
                                                    numberOfPeople: eventDetails.numberOfPeople,
                                                    deliveryDate: deliveryDate,
                                                },
                                                order: getOrder(),
                                                taxes: [{
                                                    catalogObjectId: taxData.id,
                                                    name: taxData.name,
                                                    scope: 'ORDER',
                                                }]
                                            }),
                                        }).then((response) => {
                                            if (response.ok) {
                                                // Save current cart to prevent updates after clearing the store
                                                orderDetails.current = {
                                                    deliveryInfo: deliveryInfo,
                                                    cart: cart,
                                                    taxes: taxes,
                                                    deliveryFee: serviceCharge,
                                                    serverCharge: serverCharge,
                                                    deliveryDate: deliveryDate,
                                                    total: totalPrice,
                                                };

                                                resetPaymentError();
                                                sendConfirmationEmail();
                                                toggleModal();
                                                clearStore();
                                            } else {
                                                handlePaymentError();
                                            }
                                        });
                                    }}
                                    // locationId='LFF8Q4CBJ5YEY' // TODO: Update to prod values
                                    locationId='LS23ETAA0WN16'
                                    createPaymentRequest={() => ({
                                        countryCode: "US",
                                        currencyCode: "USD",
                                        total: {
                                            amount: `${subtotalPrice}`, // does not need to be in cents
                                            label: "Total",
                                        },
                                    })}
                                    createVerificationDetails={() => ({
                                        amount: `${subtotalPrice}`,
                                        currencyCode: 'USD',
                                        intent: 'CHARGE',
                                        billingContact: billingInfo,
                                    })}
                                >
                                    <Typography gutterBottom variant='h4' sx={{ ml: 1, mt: 2 }}>Select payment method</Typography>
                                    {
                                        paymentError
                                            ? <Typography variant='subtitle1' color='error' sx={{ ml: 1 }}>Error processing payment. Please try again.</Typography>
                                            : <></>
                                    }
                                    <CreditCard buttonProps={{
                                        css: {
                                            backgroundColor: "#5D3FD3",
                                            fontSize: "14px",
                                            color: "#fff",
                                            "&:hover": {
                                                backgroundColor: "#cec5f1",
                                            },
                                        },
                                    }} >Pay ${totalPrice} with card
                                    </CreditCard>
                                    <Divider />
                                    <Ach
                                        accountHolderName={`${billingInfo.givenName} ${billingInfo.familyName}`}
                                        transactionId={`${createAchTransactionId()}`}
                                        redirectURI='https://deteatery.com/cart'
                                        intent='CHARGE'
                                        total={{
                                            amount: subtotalPrice,
                                            currencyCode: 'USD',
                                        }}
                                    >
                                        Pay ${totalPrice} with bank account (ACH)
                                    </Ach>
                                    <Divider />
                                    <GooglePay />
                                </PaymentForm>
                            }

                        </Paper>
                    </>
                        : <>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                            }}>
                                <Typography variant='h5' sx={{ pt: 5, pb: 5 }}>A minimum of $300 is required to order.</Typography>
                                <Button
                                    variant="text"
                                    href="/menu"
                                    color="primary"
                                >
                                    <Body>View menu</Body>
                                </Button>
                            </div>
                        </>
                        : <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Typography variant='h3' sx={{ pt: 10 }}>Your cart is empty.</Typography>
                        </div>
            }

        </div >
    )
}

const NumberOfPeopleDropDown = ({ value, onChange }) => {
    const numberOfPeopleOptions = Array.from({ length: 500 }, (_, i) => i + 1);

    return (
        <FormControl required fullWidth sx={{ mb: 2 }}>
            <InputLabel id="number-dropdown-label">Number of People</InputLabel>
            <Select
                name='numberOfPeople'
                variant='outlined'
                label='Number of People'
                labelId="number-dropdown-label"
                id="number-dropdown"
                value={value}
                onChange={onChange}
            >
                {numberOfPeopleOptions.map((number) => (
                    <MenuItem key={number} value={number}>
                        {number}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const EventTypeDropDown = ({ value, onChange }) => {
    const eventTypes = [
        'Birthday',
        'Graduation',
        'Anniversary',
        'Baby Shower',
        'Bridal Shower',
        'Rehearsal Dinner',
        'Retirement',
        'Bachelor Party',
        'Bachelorette Party',
        'Bar Mitzvah',
        'Bat Mitzvah',
        'Repast / Memorial Service Reception',
        'Family Reunion',
        'Wedding',
        'Quinceañera',
        'Holiday Party',
        'Business / Office / Corporate Function',
        'Other',
    ];

    return (
        <FormControl required fullWidth sx={{ mb: 2 }}>
            <InputLabel id="event-type-dropdown-label">Event Type</InputLabel>
            <Select name='eventType' variant='outlined' label='Event Type' labelId="event-type-dropdown-label" id="event-dropdown" value={value} onChange={onChange}>
                {eventTypes.map((event) => (
                    <MenuItem key={event} value={event}>
                        {event}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const ServiceDropDown = ({ value, onChange }) => {
    const serviceOptions = [
        'None',
        '2 hours',
        '4 hours',
    ];

    return (
        <FormControl required fullWidth sx={{ mb: 2 }}>
            <InputLabel id="service-option-dropdown-label">Service Required</InputLabel>
            <Select name='serviceOption' variant='outlined' label='Service Option' labelId="service-option-dropdown-label" id="service-option-dropdown" value={value} onChange={onChange}>
                {serviceOptions.map((event) => (
                    <MenuItem key={event} value={event}>
                        {event}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const DeliveryPriceList = () => {
    const tableStyle = {
        '@media (maxWidth: 600px)': {
            display: 'block',
            width: '100%',
        },
        borderCollapse: 'collapse',
    };

    const rowStyle = {
        borderBottom: '1px solid #ddd',
        padding: '8px 0',
    };

    const cellStyle = {
        padding: '8px',
        textAlign: 'left',
    };

    const priceCellStyle = {
        padding: '8px',
        textAlign: 'right',
    };

    const mobileStyle = {
        '@media (maxWidth: 600px)': {
            display: 'block',
            width: '100%',
        },
        '@media (maxWidth: 600px) td': {
            display: 'block',
            textAlign: 'left',
            padding: '8px 0',
        },
        '@media (maxWidth: 600px) td:lastChild': {
            textAlign: 'left',
        },
    };

    return (
        <table style={{ ...tableStyle, ...mobileStyle }}>
            <tbody>
                <tr style={rowStyle}>
                    <td style={cellStyle}><strong>25 miles or less:</strong></td>
                    <td style={priceCellStyle}>$75.00</td>
                </tr>
                <tr style={rowStyle}>
                    <td style={cellStyle}><strong>26-49 miles:</strong></td>
                    <td style={priceCellStyle}>$150.00</td>
                </tr>
                <tr style={rowStyle}>
                    <td style={cellStyle}><strong>50-75 miles:</strong></td>
                    <td style={priceCellStyle}>$300.00</td>
                </tr>
                <tr style={rowStyle}>
                    <td colSpan="2" style={cellStyle}><strong>Deliveries of over 75 miles are not supported</strong></td>
                </tr>
            </tbody>
        </table>
    );
};

const ServiceFeeList = () => {
    const tableStyle = {
        '@media (maxWidth: 600px)': {
            display: 'block',
            width: '100%',
        },
        borderCollapse: 'collapse',
    };

    const rowStyle = {
        borderBottom: '1px solid #ddd',
        padding: '8px 0',
    };

    const cellStyle = {
        padding: '8px',
        textAlign: 'left',
    };

    const priceCellStyle = {
        padding: '8px',
        textAlign: 'right',
    };

    const mobileStyle = {
        '@media (maxWidth: 600px)': {
            display: 'block',
            width: '100%',
        },
        '@media (maxWidth: 600px) td': {
            display: 'block',
            textAlign: 'left',
            padding: '8px 0',
        },
        '@media (maxWidth: 600px) td:lastChild': {
            textAlign: 'left',
        },
    };

    return (
        <table style={{ ...tableStyle, ...mobileStyle }}>
            <tbody>
                <tr style={rowStyle}>
                    <td style={cellStyle}><strong>Serving fee for up to 2 hours:</strong></td>
                    <td style={priceCellStyle}>$200.00</td>
                </tr>
                <tr style={rowStyle}>
                    <td style={cellStyle}><strong>Serving fee for up to 4 hours:</strong></td>
                    <td style={priceCellStyle}>$400.00</td>
                </tr>
            </tbody>
        </table>
    );
};