import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { cartReducer } from "./CartSlice";
import storage from 'redux-persist/lib/storage';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import { eventDetailsReducer } from "./EventDetailSlice";

const persistConfig = {
    key: 'root',
    storage,
}

const allReducers = combineReducers({
    cart: cartReducer,
    eventDetails: eventDetailsReducer,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store)