import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function LinkText({ children, href, ...props }) {
  return (
    <Typography
      variant="inherit"
      component="a"
      color="secondary"
      textDecoration="none"
      href={href}
      {...props}
    >
      {children}
    </Typography>
  );
}

LinkText.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string,
};

export function Body({ children, ...props }) {
  return (
    <Typography variant="body1" {...props}>
      {children}
    </Typography>
  );
}

Body.propTypes = {
  children: PropTypes.string.isRequired,
};
