import { Button, FormControl, Paper, TextField, Typography } from "@mui/material";
import Testimonials from "../components/Testimonials";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { THEME } from "../style/Themes";

export default function Reviews() {
    const formControlStyle = { marginBottom: "10px" };
    const { handleSubmit, control } = useForm();
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (data) => {
        await submitReview(data);
        // TODO: clear form and show success message
    }

    // Submit the review
    async function submitReview(data) {
        setSubmitting(true);
        return fetch('/api/testimonials', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                response.json();
                if (response.status === 200) {
                    setSubmitted(true);
                    setError(false);
                } else {
                    setSubmitted(false);
                    setError(true);
                }
            })
            .catch(error => setError(true))
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <Testimonials />
            <Paper
                elevation={3}
                style={{
                    padding: 24,
                    margin: 16,
                }}
            >
                {
                    submitted
                        ? <Typography>Thank you for your reivew. It will appear shortly.</Typography>
                        : <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth>
                                <h2>Share your experience!</h2>
                            </FormControl>
                            <FormControl fullWidth style={formControlStyle}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            required
                                            label="Name"
                                            variant="outlined"
                                            inputProps={{
                                                autocomplete: 'no',
                                                maxLength: 25,
                                                form: {
                                                    autocomplete: 'no',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>

                            <FormControl fullWidth style={formControlStyle}>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            required
                                            label="Leave a review"
                                            multiline
                                            rows={10}
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Typography variant="caption" sx={{ marginTop: 1 }}>By clicking submit, you agree to our <a href="/legal/terms-of-use.html#reviews">Terms of Use</a>.</Typography>
                                {error &&
                                    <Typography
                                        color={THEME.palette.error.main}
                                        variant="body"
                                        fontSize={'large'}
                                    >
                                        Error processing your review. Please try again later. If this issue persists, report it to <a href={'mailto:customer-support@DETeatery.com'}>customer-support@deteatery.com</a>.
                                    </Typography>
                                }

                            </FormControl>


                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: 0 }}
                                disabled={submitting}
                            >
                                Submit
                            </Button>
                        </form>
                }

            </Paper>
        </>

    );
}